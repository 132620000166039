<template>
  <div>
    <home-main-auth v-if="userAccess == 2" />
    <home-main-guest v-else-if="userAccess == 1" />
    <home-main-public v-else-if="userAccess == 0" />
    <div v-else>
      <!-- <v-row
        class="fill-height"
        align-content="center"
        justify="center"
      >
        <v-col
          class="subtitle-1 text-center"
          cols="12"
        >
          Loading
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="red accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "HomeMain",
  data() {
    return {
      userAccess: -1, // 0 - no access, 1 - guest, 2 - customer
    }
  },
  created() {
    this.refreshView()
  },
  methods: {
    async refreshView() {
      this.$store.dispatch('loading',true)
      if(this.fpUser.uid && this.fpUser.uak){
        if(!this.customer.id && !('active' in this.customer && this.customer.active == 0)){
          await this.$store.dispatch('fetchCustomer')
        }
        this.userAccess = this.customer.id ? 2 : 1;
      } else {
        this.userAccess = 0
      }
      this.$store.dispatch('loading',false)
    }
  },
  computed: {
    fpUser() {
      return this.$store.state.fp_user;
    },
    customer() {
      return this.$store.state.customer;
    }
  },
  watch: {
    customer() {
      // console.log('old',o);
      // console.log('new',n);
      // if(n.id !== o.id) this.refreshView()
      this.refreshView()
    }
  },
  components: {
    HomeMainAuth: () => import(/* webpackChunkName: "HomeMainAuth" */ "./HomeMainAuth"),
    HomeMainGuest: () => import(/* webpackChunkName: "HomeMainGuest" */ "./HomeMainGuest"),
    HomeMainPublic: () => import(/* webpackChunkName: "HomeMainPublic" */ "./HomeMainPublic"),
  },
};
</script>
